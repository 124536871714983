import {
  options,
  defaultList,
  fetchAll,
  defaultAdd,
  defaultGet,
  defaultUpdate,
  defaultDestroy,
} from "@/store/services";

const link = "stock-management/stores";

function fetchAllStocks(storeID) {
  const customLink = `${link}/${storeID}/stocks`;
  return fetchAll(customLink);
}

function get(payload) {
  const customLink = `${link}/${payload.store_id}/stocks`;
  return defaultGet(customLink, payload.data);
}

function add(payload) {
  const customLink = `${link}/${payload.store_id}/stocks`;
  return defaultAdd(customLink, payload.data);
}

function update(payload) {
  const customLink = `${link}/${payload.store_id}/stocks`;
  return defaultUpdate(customLink, { id: payload.id, ...payload.data });
}

function destroy(storeID, stockID) {
  const customLink = `${link}/${storeID}/stocks`
  return defaultDestroy(customLink, stockID);
}

export default {
  fetchAllStocks,
  get,
  add,
  update,
  destroy,
};
