import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import files_manager from './modules/folders-module';
import project from './modules/projects-module';
import todo from './modules/todos-module';
import fundHistory from './modules/fund-histories-module';
import user from './modules/users-module';
import customers from './modules/customers-module';
import userGroup from './modules/roles-module';
import projectStatus from './modules/project-status-module';
import tax from './modules/taxes-module';
import itemBrand from './modules/product-brands-module';
import itemCategory from './modules/product-categories-module';
import item from './modules/products-module';
import storeManagement from './modules/stores-management-module';
import stock from './modules/stocks-module';
import invoices from './modules/invoices-module';
import employee from './modules/employees-module';
import permission from './modules/permissions-module';
import auth from './modules/auth';
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import cashRegister from "./modules/cash-registers-module";
import subCashRegister from "./modules/sub-cash-registers-module";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        app,
        appConfig,
        verticalMenu,

        // apps
        files_manager,
        project,
        todo,
        fundHistory,
        cashRegister,
        subCashRegister,
        // permission,
        user,
        customers,
        userGroup,
        projectStatus,
        tax,
        item,
        itemBrand,
        itemCategory,
        storeManagement,
        stock,
        invoices,
        employee,
    },
    strict: process.env.DEV,
})